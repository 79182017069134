<template>
  <div class="cpt-task-list-search">
    <div style="display: flex; align-items: center">
      <div @click="$emit('on-edit')" class="linear-btn">添加要素</div>
      <div class="seach">
        <el-input
          placeholder="请输入要素名称"
          v-model="search_form.name"
          class="input-with-name"
          clearable
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            style="padding: 0"
            @click="$emit('on-search', search_form)"
          ></el-button>
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search_form: {
        name: null,
      },
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.cpt-task-list-search {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  .seach {
    padding-left: 16px;
    .input-with-name {
      width: 310px;
      height: 36px;
    }
    ::v-deep .el-input-group__append {
      height: 36px;
      text-align: right;
      padding-top: 3px;
    }
    ::v-deep .el-input__suffix {
      text-align: center;
    }
  }
}
</style>
<style lang="scss">
.seach .input-with-name .el-input__inner,
.seach .input-with-name .el-input-group__append {
  box-sizing: border-box;
  background: rgba(12, 34, 67, 0.5);
  border: 1px solid #009aff;
  border-radius: 0;
  font-size: 16px;
  color: #9fc4ff;
  letter-spacing: 0;
  font-weight: 400;
  height: 36px;
}
.seach .input-with-name .el-input__inner {
  border-right: none;
}
.seach .input-with-name .el-input-group__append {
  border-left: none;
}
</style>
