<template>
  <List />
</template>

<script>
import List from "./list"
export default {
  components: {
    List
  }
}
</script>

<style>

</style>