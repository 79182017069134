<template>
  <div class="page-task-list">
    <TaskList
      :onEdit="addList"
      :edit="
        (value) => {
          edit(value);
          visible.edit = true;
        }
      "
      ref="listRef"
    />
    <el-dialog
      :title="editProps ? '编辑要素' : '添加要素'"
      :close-on-click-modal="false"
      :visible.sync="visible.edit"
      width="400px"
      @close="close"
      @closed="closed"
    >
      <el-form
        ref="edit_form"
        :rules="rules.edit_form"
        :model="edit_form"
        class="linear-form vertical"
      >
        <el-form-item label="要素名称" prop="name">
          <el-input
            v-model="edit_form.name"
            placeholder="请输入要素名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="要素图标" prop="icoId">
          <el-select
            v-model="edit_form.icoId"
            size="medium"
            placeholder="请选择图标"
          >
            <el-option
              v-for="item in icoSelect"
              :key="item.id"
              :label="item.icoName"
              :value="item.id"
            >
              <span style="float: left">{{ item.icoName }}</span>
              <el-image
                style="width: 36px; height: 36px; float: right"
                :src="baseUrl + item.icoUrl"
                :preview-src-list="[baseUrl + item.icoUrl]"
              ></el-image>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="要素类型" prop="typeId">
          <el-select
            v-model="edit_form.typeId"
            size="medium"
            placeholder="请选择要素类型"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.typeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经度" prop="longitude">
          <el-input-number
            v-model="edit_form.longitude"
            placeholder="经度"
            clearable
          />
        </el-form-item>

        <el-form-item label="纬度" prop="latitude">
          <el-input-number
            v-model="edit_form.latitude"
            placeholder="纬度"
            clearable
          />
        </el-form-item>
        <div class="form-submit-item">
          <div @click="handleSave" class="linear-btn">保存</div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api";
import TaskList from "./list";

export default {
  components: {
    TaskList,
  },
  data() {
    return {
      editProps: null,
      visible: {
        edit: false,
        add: true,
        notice: false,
      },
      edit_form: {
        latitude: null,
        longitude: null,
        typeId: null,
        name: null,
        icoId: null,
      },
      rules: {
        edit_form: {
          latitude: [
            { required: true, message: "请输入纬度", trigger: "blur" },
            {
              type: "number",
              min: 20,
              max: 40,
              message: "请输入正确纬度",
              trigger: "blur",
            },
          ],
          longitude: [
            { required: true, message: "请输入经度", trigger: "blur" },
            {
              type: "number",
              min: 110,
              max: 130,
              message: "请输入正确经度",
              trigger: "blur",
            },
          ],
          typeId: [
            { required: true, message: "请选择要素类型", trigger: "blur" },
          ],
          name: [
            { required: true, message: "请输入要素名称", trigger: "blur" },
          ],
          icoId: [{ required: true, message: "请选择图标", trigger: "blur" }],
        },
      },
      icoSelect: [],
      typeList: [],
      baseUrl: null,
    };
  },
  methods: {
    async handleSave() {
      this.$refs["edit_form"].validate(async (valid) => {
        if (valid) {
          let res;
          if (this.editProps) {
            res = await API.POI.upInfo({
              ...this.edit_form,
              id: this.editProps.id,
            });
          } else {
            res = await API.POI.addInfo(this.edit_form);
          }

          if (res.code === 200) {
            this.$el_message(this.editProps ? "修改成功" : "创建成功");
            this.$refs["edit_form"].resetFields();
            this.editProps = null;
            this.visible.edit = false;
            this.$refs.listRef.async_get_list();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    addList() {
      this.visible.edit = true;
      this.edit_form = {
        latitude: null,
        longitude: null,
        typeId: null,
        name: null,
        icoId: null,
      };
    },
    edit(value) {
      this.editProps = value;
      this.init_form(value);
      this.edit_form.name = value.elementName;
    },
    init_form(data) {
      Object.keys(this.edit_form).forEach((key) => {
        this.edit_form[key] = data[key] || null;
      });
    },
    close() {
      this.visible.edit = false;
      //  this.editProps = null
      // this.$refs["edit_form"].resetFields();
    },
    closed() {
      this.editProps = null;
      this.$refs["edit_form"].resetFields();
    },
    async icoList() {
      const { data } = await API.POI.IcoList();
      this.icoSelect = data;
    },
    async getTypeList() {
      const res = await API.POI.getType({
        pageNum: 1,
        pageSize: 100,
      });
      if (res.data.records) {
        this.typeList = res.data.records;
      }
    },
  },
  mounted() {
    this.icoList();
    this.getTypeList();
    this.baseUrl = process.env.VUE_APP_WS_IP;
  },

  inject: ["toggle_mode"],
};
</script>

<style lang="scss" scoped>
.page-task-list {
  box-sizing: border-box;
  padding: 32px;
  height: 100%;
  .task-list-notice-wrap {
    display: flex;
    .map-box {
      width: 436px;
      height: 274px;
      border: 1px solid red;
      flex-shrink: 0;
      box-sizing: border-box;
      margin-right: 16px;
    }
    .props-box {
      flex: 1;
      box-sizing: border-box;
      .prop-field {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
        .prop-field-k {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          text-align: left;
          font-weight: 400;
          width: 98px;
        }
        .prop-field-v {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
