var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',[_c('div',{staticClass:"page-poi-task-list"},[_c('Search',{on:{"on-edit":_vm.onEdit,"on-search":_vm.handle_search}}),_c('Table',{staticClass:"task-table",attrs:{"columns":_vm.columns,"times":_vm.times,"dataSource":_vm.dataSource,"pagination":_vm.pagination},on:{"size-change":(size) => {
          _vm.pagination.pageSize = size;
          _vm.async_get_list();
        },"page-change":(page) => {
          _vm.pagination.page = page;
          _vm.async_get_list();
        }},scopedSlots:_vm._u([{key:"ico",fn:function({ row }){return [_c('el-image',{staticStyle:{"width":"50px","height":"42px"},attrs:{"src":_vm.baseUrl + row.ico,"preview-src-list":[_vm.baseUrl + row.ico]}})]}},{key:"longitude",fn:function({ row }){return [_c('span',[_vm._v(_vm._s(row.longitude))])]}},{key:"latitude",fn:function({ row }){return [_c('span',[_vm._v(_vm._s(row.latitude))])]}},{key:"action",fn:function({ row }){return [_c('div',{staticClass:"action"},[_c('span',{staticClass:"iconfont icon-genggai",attrs:{"title":"更改"},on:{"click":function($event){return _vm.edit(row)}}})])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }