<template>
  <Box>
    <div class="page-poi-task-list">
      <Search @on-edit="onEdit" @on-search="handle_search" />
      <Table
        class="task-table"
        :columns="columns"
        :times="times"
        :dataSource="dataSource"
        :pagination="pagination"
        @size-change="
          (size) => {
            pagination.pageSize = size;
            async_get_list();
          }
        "
        @page-change="
          (page) => {
            pagination.page = page;
            async_get_list();
          }
        "
      >
        <template #ico="{ row }">
          <el-image
            style="width: 50px; height: 42px"
            :src="baseUrl + row.ico"
            :preview-src-list="[baseUrl + row.ico]"
          ></el-image>
        </template>
        <template #longitude="{ row }">
          <span>{{ row.longitude }}</span>
        </template>
        <template #latitude="{ row }">
          <span>{{ row.latitude }}</span>
        </template>
        <template #action="{ row }">
          <div class="action">
            <span
              title="更改"
              class="iconfont icon-genggai"
              @click="edit(row)"
            ></span>
          </div>
        </template>
      </Table>
    </div>
  </Box>
</template>

<script>
import Box from "@/components/platform/common/box";
import Search from "./search.vue";
import Table from "@/components/common/table";
import { props } from "./props";
import API from "@/api";
export default {
  props: _.pick(props, ["onEdit", "edit"]),
  components: { Box, Search, Table },
  methods: {},
  data() {
    return {
      columns: [
        { prop: "elementName", label: "要素名称" },
        { prop: "ico", label: "要素图标", slot: true },
        { prop: "type", label: "要素类型" },
        { prop: "longitude", label: "经度", slot: true },
        { prop: "latitude", label: "纬度", slot: true },
        { prop: "createTime", label: "创建时间" },
        { prop: "updateTime", label: "更新时间" },
        { prop: "action", slot: true, label: "操作" },
      ],
      times: null,
      dataSource: [],
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      search: {},
      baseUrl: null,
    };
  },
  methods: {
    async async_get_list() {
      const { page, pageSize } = this.pagination;
      let { data } = await API.POI.List({
        ...this.search,
        pageNum: page,
        pageSize: pageSize,
      });

      this.dataSource = data.records || [];
      this.pagination.page = data.current;
      this.pagination.pageSize = data.size;
      this.pagination.total = data.total;
      // this.times =
    },
    handle_search(data) {
      this.pagination.page = 1;
      this.search = data;
      this.async_get_list();
    },
    log(data) {},
  },
  mounted() {
    this.async_get_list();
    this.baseUrl = process.env.VUE_APP_WS_IP;
  },
};
</script>

<style lang="scss" scoped>
.page-poi-task-list {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .task-table {
    flex: 1;
    margin-top: 27px;
    .action {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      .iconfont {
        cursor: pointer;
        font-size: 18px;
        color: #9fc4ff;
        margin-right: 4px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
</style>
